<template>
    <!-- start inner banner  -->
    <section class="inner-banner">
        <div class="card">
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <h5 class="card-title">View client</h5>
                </li>
                <li class="list-group-item">
                    <h5 class="card-title">Company Name</h5>
                    {{ company.name }}
                </li>
                <li class="list-group-item">
                    <h5 class="card-title">Company Owner</h5>
                    {{ company.owner }}
                </li>
                <li class="list-group-item">
                    <h5 class="card-title">Responsible</h5>
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Full Name</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Group</th>
                            <th scope="col">Active</th>
                            <th scope="col">Last Login date</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">{{ company.responsible.id }}</th>
                            <td>{{ company.responsible.first_name }} {{ company.responsible.last_name }}</td>
                            <td>{{ company.responsible.email }}</td>
                            <td>{{ company.responsible.group }}</td>
                            <td>{{ company.responsible.active ? 'Active' : 'Not active' }}</td>
                            <td>{{ company.responsible.last_login || 'N/A' }}</td>
                            <td>
                                <router-link :to="{ name: 'ViewUser', params: { id: company.responsible.id } }">view
                                </router-link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <button class="btn btn-primary" @click="showAddOrChangeResponsibleUser=true">Change Responsible User</button>
                </li>
                <li class="list-group-item">
                    <h5 class="card-title">Created at</h5>
                    {{ company.created_at }}
                </li>
                <li class="list-group-item">
                    <h5 class="card-title">Updated at</h5>
                    {{ company.updated_at }}
                </li>
            </ul>

            <div class="card-body">
                <router-link :to="{ name: 'EditClient', params: { id: company.id } }" class="card-link">Edit Company
                </router-link>
            </div>

        </div>
    </section>

    <add-or-change-responsible-user v-if="showAddOrChangeResponsibleUser"
                                    @closeModal="showAddOrChangeResponsibleUser=false"
                                    @responsibleSelected="setResponsible"
    />
</template>

<script>
import axios from "@/axios";
import AddOrChangeResponsibleUser from "@/components/companies/AddOrChangeResponsibleUser";
import {responsibleService} from "@/services/responsibleService";
import {companyService} from "@/services/companyService";

export default {
    name: "ViewCompany",
    data() {
        return {
            company: {
                responsible: {}
            },
            showAddOrChangeResponsibleUser: false,
        }
    },
    computed: {
        companyId() {
            return this.$route.params.id
        }
    },
    created() {
        this.getCompany()
    },
    methods: {
        getCompany() {
            companyService.getCompany(this.companyId)
                .then(response => this.company = response.data.data)
        },
        setResponsible(user) {
            if (this.company.responsible.id !== user.id) {
                responsibleService.setCompanyResponsible(this.companyId, user.id)
                    .then(() => this.company.responsible = user)
            }
            this.showAddOrChangeResponsibleUser = false
        }
    },
    components: {
        AddOrChangeResponsibleUser
    }
}
</script>

<style scoped>
li {
    margin-bottom: 0;
}
</style>